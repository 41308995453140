import React from 'react'

function Loader() {
  return (
    <div>
      {' '}
      <div className="loader">
        <div className="loader-sizing">
          <div className="cssload-loader">
            Hack<span>4.0</span>wasp..
          </div>
        </div>
      </div>
    </div>
  )
}

export default Loader
